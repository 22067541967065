import React from 'react'

const Blog = () => {   


    
    return (
        <div>
           
            <div className='title'>
                JML Digital Blog
            </div>
            <div className='allblogs'>
                <a href='/capturing-success'>
                <div className='oneblog'>
                    <div className='blogtitle'>Capturing Success</div>
                    <div className='blog2ndtitle'> The Importance of High-Quality Visuals in Real Estate Marketing</div>
                    <img src='images/CapturingSuccessImage.jpg'alt="Row House/ Town Home For Sale"/>
                    <div>
                        <p>In the fast-paced world of real estate, first impressions matter. With the majority of homebuyers starting their property search online, the visual presentation of a listing plays a pivotal role in capturing their attention and interest. In this blog post, we delve into the crucial aspect of real estate marketing... READ MORE
                        </p>
                    </div>
                </div>
                </a>
            </div>
        </div>

        )}
 
export default Blog