import React from 'react';
import { motion } from 'framer-motion';

const Menu = () => {
    return (
        <motion.div 
            className="navbar"
            transition={{ duration: 2 }} 
            animate={{ opacity: 1, x: 0 }} 
            initial={{ opacity: 0, x: 100 }}
        >
            {/* Logo on the left */}
            <div className="logo">
                <a href="/">
                    <img src="images/JMLOrderLogo.png" alt="Welcome to JML Digital Real Estate Marketing Blog" />
                </a>
            </div>

            {/* Links on the right */}
            <ul className="nav-links">
                <li>
                    <a href="/#jordan">About</a> {/* Direct link to the section on the home page */}
                </li>
                <li>
                    <a href="/#services">Services</a> {/* Direct link to the section on the home page */}
                </li>
                <li>
                    <a href="/blog">Blog</a>
                </li>
                <li>
                    <a href="http://order.jmldigital.com/login" target='_blank' rel="noopener noreferrer">Login</a>
                </li>
                <div className='buttcenter'>
                    <a href='http://order.jmldigital.com/order' target='_blank' rel="noopener noreferrer">
                        <motion.button 
                            whileHover={{scale: 1.1}}
                            className='book-now-button'  
                        >
                            Book My Shoot
                        </motion.button>
                    </a>  
                </div>
            </ul>
        </motion.div>
    );
};

export default Menu;
