import React from 'react';
import ReactPlayer from 'react-player';

const Services = () => {
    return (
<div>
        <h1 className='services'>Services</h1>
        <div className="services-container">
            
            {/* Photography Section */}
            <div className="service-column">
                <h1 className="servtitle">Photo</h1>
                <img src="images/photoimg.png" className='servimg' loading='lazy' alt="Real Estate Photography" />
                <p className='servdes'>
                    JML Digital specializes in capturing the essence of your property through stunning imagery that not only showcases its unique features but also tells a compelling visual story. <br /><br />
                    Our skilled photographers understand the pivotal role photography plays in selling real estate. <br /><br />
                    We combine artistic flair with technical expertise to deliver high-quality photographs that make your listings stand out.<br /><br />
                    Whether you're a real estate agent looking to enhance your marketing materials or a homeowner seeking to highlight your property's potential, we're here to transform your spaces into visual masterpieces.<br /><br />
                    Discover how our real estate photography services can elevate your listings and boost your sales.
                </p>
            </div>

            {/* Videography Section */}
            <div className="service-column">
                <h1 className="servtitle">Video</h1>
                <div className="youtube-video-container">
                    <ReactPlayer
                        url='https://www.youtube.com/watch?v=WKQkXgLFquo'
                        className='react-player'
                        width='100%'
                        height='100%'
                    />
                </div>
                <p className='servdes'>
                    Bring your listings to life through captivating video content. Our team specializes in crafting immersive visual experiences that not only showcase properties but also engage and resonate with potential buyers. <br /><br />
                    With state-of-the-art equipment and a keen understanding of real estate dynamics, we create high-definition videos that highlight the unique features and charm of each property. <br /><br />
                    Whether it's a cinematic walkthrough, a virtual tour, or a compelling property showcase, our videos convey the essence of your listings in a way that static images simply cannot match.<br /><br />
                    Join us in redefining real estate marketing with dynamic video content that helps you connect with your audience on a whole new level.
                </p>
            </div>

            {/* Drone Section */}
            <div className="service-column">
                <h1 className="servtitle">Drone</h1>
                <img src="images/droneimg.png" className='servimg' loading='lazy' alt="Real Estate Drone Aerial"/>
                <p className='servdes'>
                    Our aerial photography and videography solutions redefine how you showcase properties. <br /><br />
                    Using cutting-edge drone technology, we capture breathtaking aerial perspectives that give your real estate listings a competitive edge. <br /><br />
                    With a keen eye for composition and a commitment to detail, our licensed drone pilots ensure every shot tells a compelling story. <br /><br />
                    Whether you're marketing luxury estates, commercial properties, or land developments, our drone services provide a unique vantage point that highlights key features and the surrounding environment.<br /><br />
                    Elevate your real estate marketing strategy with stunning aerial imagery that leaves a lasting impression.
                </p>
            </div>

            {/* 2D, 3D & Virtual Staging Section */}
            <div className="service-column">
                <h1 className="servtitle">Virtual</h1>
                <img src="images/3dimg.png" className='servimg' loading='lazy' alt="Real Estate Virtual Staging"/>
                <p className='servdes'>
                    Our comprehensive suite of services includes 2D and 3D renderings and virtual staging, all designed to enhance the marketability of your properties.<br /><br />
                    Our 2D renders provide a birds-eye depiction of the space, offering a glimpse into the potential of the estate as a whole.<br /><br />
                    Meanwhile, our 3D renders take it a step further, immersing viewers in a lifelike, three-dimensional experience that showcases every detail, inside and out. <br /><br />
                    Go fully into what is possible with virtual staging that enables you to unlock the full potential of your empty spaces. <br /><br />
                    We can furnish and decorate rooms virtually, helping buyers visualize their dream homes while maximizing the appeal of your listings and expediting the sales process.
                </p>
            </div>
        </div>
        </div>
    );
}

export default Services;