import React from 'react';

const Jordan = () => {
    return (
        <div><h1>About</h1>
        <div className="jordan-section">
        <div className="jordan-image">
                <img src="/images/Jordan.png" alt="Jordan Michael Lugenbeel" />
            </div>
            <div className="jordan-content">
                <h1>Jordan Michael Lugenbeel</h1>
                <p>
                I’m excited to introduce myself and to help you sell houses. </p> <p>I focus on showcasing properties in the best light, making it easier for realtors like you to attract buyers. My goal is to provide you with the tools and support you need to stand out in the competitive real estate market.</p>

                <p>I'm dedicated to delivering quality and service. </p>
                <p>Thank you for considering me as your partner in real estate!
                </p>
            </div>
            
        </div>
        </div>
    );
};

export default Jordan;
